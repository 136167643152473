$accent: #ffd54b;
$dark-background: #212123;
$semi-dark-background: #262627;
$background: #302f2f;
$complement: #1d1d1f;
$blue-accent: #31beff;
$blue-background: #2c4955;
$shine-background: #3c382c;
$shine: #fffdb0;
$dark-border: #33363a;
$border: #767676;
$white: #fcfcfc;
$muted: #b1b1b1;

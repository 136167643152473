@import '../../../vars.sass';

.tree {
    margin: 0;
    padding: 0;
    font-weight: 500;
    ul {
        padding-left: 32px; }
    &, ul {
        list-style-type: none;
        .caret {
            cursor: pointer;
            user-select: none;
            &::before {
                content: ">";
                display: inline-block;
                margin-right: 6px;
                font-weight: bold;
                transition: transform 250ms;
                transform: translateY(1px); }
            &.down::before {
                transform: rotate(90deg) translateY(1px); } }
        &.nested {
            pointer-events: none;
            opacity: 0;
            line-height: 0;
            max-height: 0;
            transition-duration: 250ms;
            transition-property: opacity line-height;
            &.active {
                pointer-events: all;
                max-height: unset;
                opacity: 1;
                line-height: 1.2; } } } }

@import 'vars.sass';

* {
    box-sizing: border-box; }

#app {
    font-size: 19px;
    background-color: $background;
    height: 100%;
    width: 100%;
    display: flex;
    color: $white; }

button, input[type="button"], .button {
    text-transform: uppercase;
    border: 2px solid $border;
    padding: 10px 20px;
    background-color: transparent;
    cursor: pointer;
    color: inherit;
    border-radius: 10px;
    font-weight: 700;
    font-size: smaller;
    font-family: inherit;
    margin: 10px;
    &.shining {
        background-color: $accent;
        border-color: $accent;
        color: $semi-dark-background; }
    &.shining:hover {
        background-color: $accent;
        border-color: $shine;
        border-width: 4px;
        padding: 8px 18px;
        box-shadow: 0px 0px 8px $shine, inset 0 0 8px $shine; }
    &:hover {
        background-color: $semi-dark-background;
        border-color: $white; }
    &:active:not(.shining) {
        background-color: $shine-background;
        border-color: $accent;
        color: $accent; }
    &:active.shining {
        background-color: $shine;
        border-color: $shine;
        box-shadow: 0px 0px 8px $shine; } }

#left-content {
    padding: 20px; }

#left-col {
    min-width: 476px; }

.resize-vertical-divider {
    min-width: 4px;
    height: 100%;
    background-color: $semi-dark-background;
    cursor: ew-resize; }

#right-col {
    flex: 1; }

.tabbed {
    background-color: $semi-dark-background;
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    user-select: none;
    padding-top: 16px;
    text-transform: uppercase;
    li {
        height: 32px;
        position: relative; }
    a {
        text-decoration: none;
        font-weight: 500;
        color: inherit;
        border-bottom: 2px solid transparent;
        background-color: $dark-background;
        border: 2px solid $dark-border;
        border-bottom: none;
        padding-bottom: 6px;
        text-align: center;
        padding: 11px 30px;
        border-radius: 5px;
        position: relative;
        margin: 0 12px;
        color: $muted;

        &::before, &::after {
            background-color: inherit;
            height: calc(100%);
            position: absolute;
            display: block;
            width: 20px;
            content: "";
            top: 0px; }
        &::before {
            border-left: 2px solid $dark-border;
            border-top-left-radius: 5px;
            transform: skewX(-11.25deg);
            left: -6px; }
        &::after {
            border-right: 2px solid $dark-border;
            border-top-right-radius: 5px;
            transform: skewX(11.25deg);
            right: -6px; }
        &.active, &.shining {
            background-color: $accent;
            color: $complement;
            border-color: $accent;
            &::after, &::before {
                border-color: inherit; } }
        &:not(.active):not(:disabled):not(.shining):hover {
            background-color: $shine-background;
            color: $accent;
            border-color: $accent;
            &::after, &::before {
                border-color: inherit; } } } }
